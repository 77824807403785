import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';

const IndexPage = () => (
  <Layout>
    <SEO title="Design & Development Studio" />
    <div style={{ textAlign: 'center' }}>
      <h1 className={styles.hello}>Hello</h1>
      <h4 className={styles.subTitle}>
        We're a design and development studio based in the City of Angels that
        is creatively solving the undefined by means of
      </h4>
      <ul className={styles.services}>
        <li>Brand Identity</li>
        <li>Business Consulting</li>
        <li>Software Development</li>
        <li>UI + UX Design</li>
        <li>
          <a
            href="https://www.instagram.com/champagnepugi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Playing Fetch
          </a>
        </li>
      </ul>
      <div className={styles.contact}>
        <a href="mailto:hello@undefinedsolutions.com">
          Curious? Let's talk{' '}
          <FontAwesomeIcon className={styles.envelope} icon={faEnvelope} />
        </a>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
